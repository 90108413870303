
<script setup>
    import MFilterFields from "./components.filter.FilterFields.vue";
    import MFilterOrders from "./components.filter.FilterOrders.vue";
</script>

<template>
    <m-filter-fields v-bind="$attrs" />
    <m-filter-orders v-bind="$attrs" />
</template>
